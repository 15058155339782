<template>
  <div>
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>模型文件夹</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-table
      :data="tableData"
      ref="folderList"
      @row-click="handleRowClick"
      class="a-table"
      v-loading="loading.table"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column label="文件夹名称" prop="folderName">
        <template slot-scope="scope">
          <i class="el-icon-folder"></i
          ><span style="margin-left: 0.5rem; cursor: pointer">{{
            scope.row.folderName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="数量" prop="modelCount" />
      <el-table-column label="上传日期" prop="uploadTime" />
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="handleDeleteFolder(scope.row)"
            size="mini"
            type="danger"
            >删除</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <div class="a-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.currentPage"
        :page-sizes="pageInfo.pageSizes"
        :page-size="pageInfo.pageSize"
        :layout="pageInfo.layout"
        :total="pageInfo.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      pageInfo: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        layout: "total, sizes, prev, pager, next, jumper",
        currentPage: 1,
        total: 1,
      },
      loading: {
        table: true,
        expand: true,
      },
      customerId: localStorage.getItem("customerId"),
    };
  },
  mounted() {
    this.getFolderList();
  },
  methods: {
    /**
     * 请求方法
     */
    /**获取文件夹列表请求 */
    getFolderList() {
      let page = {
        pageIndex: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
      };
      this.$axios
        .getFolderList({ ...page, customerId: this.customerId })
        .then((res) => {
          if (!res.error) {
            this.pageInfo.total = res.totalCount;
            this.tableData = res.data.map((folder) => {
              return {
                folderName: folder.folderName,
                id: folder.id,
                modelCount: folder.modelCount,
                models: [],
                uploadTime: this.$utils.dateToString(folder.uploadTime),
              };
            });
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
          this.loading.table = false;
        });
    },
    /**删除文件夹 */
    handleDeleteFolder(row) {
      console.log(row);
      this.$axios.deleteFolder({ folderId: row.id }).then((res) => {
        console.log(res);
      });
    },
    /**分页 */
    // 页面尺寸改变
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getFolderList();
    },
    // 当前页改变
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
      this.getFolderList();
    },
    /**
     * 表格操作处理
     */
    /**处理点击行 */
    handleRowClick(row) {
      localStorage.setItem(
        "model",
        JSON.stringify({ folderId: row.id, folderName: row.folderName })
      );
      this.$router.push(`model/${row.id}`);
    },
  },
};
</script>
